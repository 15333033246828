
import { computed, defineComponent, onMounted, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import loaderClientVue from "../components/loader-client.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ScheduleType",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    // const vehicle = ref({
    //   id: "",
    //   brand: {
    //     id: null,
    //     brand: null,
    //     id_inteli: null,
    //   },
    //   model: {
    //     id: null,
    //     model: null,
    //     id_inteli: null,
    //   },
    //   version: {
    //     id: null,
    //     version: null,
    //     id_inteli: null,
    //   },
    //   year: {
    //     year: null,
    //     id_inteli: null,
    //   },
    // });

    const vehicle = ref({
      id: "",
      brand:"",
      model:"",
      version:"",
      year:"",
    });

    const extra_data = ref({
      has_agency: "",
      has_appraiser: "",
      interested_vehicle_id: "",
    });

    const getVehicle = async (id) => {
      try {
        const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/vehicle/" + id);
        
        if (data.success) {
          vehicle.value = data.data;
        }
        
      } catch (error) {
        console.log(error);
      }
    };

    onBeforeMount(async () => {
      let appointment_customer = JSON.parse(localStorage.getItem("appointment_customer") || "{}");
      vehicle.value = JSON.parse(localStorage.getItem("appointment_vehicle") || "{}");
      extra_data.value = JSON.parse(localStorage.getItem("extra_data") || "{}");

      // console.log(appointment_customer);
    });

    onMounted(async () => {

    });

    return {
      vehicle,
      extra_data,
      getVehicle,
    };

  },
});
